<template>
    <modal ref="modalEditarCompra" titulo="Editar orden de compra" no-aceptar adicional="Editar" @adicional="validarCampos">
        <cargando v-if="cargando" />
        <div class="row mx-0">
            <p class="col-12 text-center text-general f-12">
                Indique en que momento y a que CEDIS llegarán los productos
            </p>
        </div>
        <ValidationObserver ref="validacion">
            <div class="row mx-0 mt-4">
                <div class="col">
                    <ValidationProvider v-slot="{errors}" rules="required" name="Fecha">
                        <p class="label-mini">
                            Seleccionar fecha
                        </p>
                        <el-date-picker
                        v-model="fecha_inicial"
                        class="w-100"
                        size="small"
                        type="date"
                        placeholder="Seleccionar"
                        format="d [de] MMMM [del] yyyy"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                        />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 mt-4">
                <div class="col">
                    <p class="label-mini">
                        Hora
                    </p>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col">
                    <ValidationProvider v-slot="{errors}" rules="required" name="Hora Inicial">
                        <el-time-select
                        v-model="hora_inicial"
                        class="w-100"
                        size="small"
                        placeholder="Hora inicio"
                        format="hh:mm a"
                        value-format="HH:mm"
                        :picker-options="{
                            start: '00:00',
                            step: '00:30',
                            end: '23:00',
                            format: 'hh:mm a',
                            valueFormat:'HH:mm',
                            maxTime: hora_final
                        }"
                        />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col">
                    <ValidationProvider v-slot="{errors}" rules="required" name="Hora Fin">
                        <el-time-select
                        v-model="hora_final"
                        class="w-100"
                        size="small"
                        placeholder="Hora fin"
                        format="hh:mm a"
                        value-format="HH:mm"
                        :picker-options="{
                            start: '00:00',
                            step: '00:30',
                            end: '23:00',
                            format: 'hh:mm a',
                            valueFormat:'HH:mm',
                            minTime: hora_inicial
                        }"
                        />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 mt-4">
                <div class="col-12 pr-0">
                    <p class="label-mini">
                        Proveedor
                    </p>
                    <el-select v-model="id_proveedor" size="small" class="w-100" :clearable="true" @clear="limpiarProveedor">
                        <el-option
                        v-for="proveedor in proveedores"
                        :key="proveedor.id"
                        :label="proveedor.nombre"
                        :value="proveedor.id"
                        />
                    </el-select>
                </div>
                <div class="col-12 pr-0 mt-4">
                    <p class="label-mini">
                        Comentario
                    </p>
                    <el-input v-model="comentario" :rows="4" type="textarea" class="w-100" />
                </div>
            </div>
            <div class="row mx-0 pl-3 mt-3">
                <div class="col-12 d-flex">
                    <el-upload
                    ref="upload"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :on-change="changeFile"
                    :on-remove="removeFile"
                    :auto-upload="false"
                    :multiple="false"
                    :limit="1"
                    accept=".xlsx"
                    >
                        <div class="bg-general2 text-white br-8 px-3 py-1 f-16 cr-pointer shadow">
                            Click para subir archivo
                        </div>
                    </el-upload>
                </div>
            </div>
            <div class="row mx-0 pl-3 mt-3">
                <div class="col-12 d-flex">
                    <small>{{ adjunto != '' ? 'Archivo adjunto' : 'No tiene archivo adjunto' }}</small>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Ordenes from "../../../../../services/cedis/cedis_ordenes_compras"
import moment from 'moment'

export default {
    data(){
        return {
            cargando: false,
            id_oc: '',
            proveedores: [],
            hora: '',
            fecha_inicial: '',
            id_cedi: '',
            cedi: '',
            id_proveedor: '',
            comentario: '',
            file: '',
            fileList: [],
            fecha_min: '',
            adjunto: '',
            datos: [],
            hora_inicial: '',
            hora_final: '',
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < moment().subtract(1,'days');
                }
            }
        }
    },
    methods: {
        async getInfo(){
            try {
                const {data} = await Ordenes.getInfoModalGuardarOC(this.id_cedi);
                this.proveedores = data.proveedores;
                this.fecha_min = data.fecha_actual;
            } catch (e){
                this.error_catch(e)
            }
        },

        async validarCampos(){
            const valid = await this.$refs.validacion.validate()
            if(!valid){
                return this.notificacion('Mensaje', 'Los campos marcados son obligatorios', 'warning')
            }
            this.cargando = true;
            this.putActualizarOrdenCompra();
        },

        async putActualizarOrdenCompra(){
            try {
                let form = {
                    hora_inicio: this.hora_inicial,
                    hora_fin: this.hora_final,
                    fecha_inicial: this.fecha_inicial,
                    id_cedi: this.id_cedi,
                    id_proveedor: this.id_proveedor,
                    comentario: this.comentario,
                    file: this.file,
                    adjunto: this.adjunto
                }
                form = this.crear_formData(form)
                const {data} = await Ordenes.putActualizarOrdenCompra(this.id_oc, form);
                this.notificacion('Mensaje', 'Se actualizo la orden de compra correctamente', data.tipo);
                this.cargando = false;
                this.$emit('consultar-orden')
                this.limpiar();
                this.$refs.modalEditarCompra.toggle();
            } catch (e){
                this.error_catch(e)
            }
        },

        changeFile(file){
            this.file = file.raw
        },

        removeFile(){
            this.file = ''
        },

        toggle(datos,id){
            this.limpiar();
            this.id_oc = id;
            this.datos = datos;
            this.mapearVariables();
            this.$refs.modalEditarCompra.toggle();
        },

        mapearVariables(){
            this.$refs.validacion.reset();
            this.hora_inicial = this.datos.hora[0];
            this.hora_final = this.datos.hora[1],
            this.fecha_inicial = this.datos.hora_inicio;
            this.id_cedi = this.datos.id_cedis;
            this.cedi = this.datos.cedi;
            this.comentario = this.datos.observaciones;
            this.adjunto = this.datos.adjunto_bd;
            this.getInfo();
            this.id_proveedor = this.datos.id_proveedor;
        },

        limpiar(){
            this.$refs.validacion.reset();
            this.cargando = false;
            this.hora_inicial = '';
            this.hora_final = '';
            this.fecha_inicial = '';
            this.id_cedi = '';
            this.cedi = '';
            this.id_proveedor = '';
            this.comentario = '';
            this.file = '';
            this.adjunto = '';
            this.$refs.upload.clearFiles();
        },
        limpiarProveedor(){
            this.id_proveedor = "";
        },
    }
}
</script>
<style lang="css" scoped>
.label-mini{
    font-size: 12px !important;
    color: #000000;
    padding-left: 15px;
}
.btn-alternative{
    background-color: #707070;
    border-radius: 8px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
}
</style>
